import React from 'react';
import './index.css';

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='section'>
        <div className='centerFlex usualGap'>
          <div className='smallIcon emailIcon lightBackground' />
          <a
            href='mailTo:info@btg-manching.de'
            className='boldText contactCombination c2'
          >
            info@btg-manching.de
          </a>
        </div>
        <div className='centerFlex' style={{ justifyContent: 'space-between' }}>
          <div className='boldText'>
            <a href='/Datenschutz' className='c2'>
              Datenschutz
            </a>
          </div>
          <div className='boldText'>
            <a href='/Satzung' className='c2'>
              Satzung
            </a>
          </div>
          <div className='boldText'>
            <a href='/Impressum' className='c2'>
              Impressum
            </a>
          </div>
        </div>
        <div className='author lightText '>
          <a
            href='https://www.coding-lane.com'
            target='_blank'
            rel='noreferrer'
            className='c2'
          >
            powered by coding-lane.com
          </a>
        </div>
        <p className='policydescription c3'>
          * Alle Preise inkl. der gesetzlichen MwSt. Produkte ohne Dekoration.
        </p>
      </div>
    </footer>
  );
};
